// Here you can add other styles
.c-sidebar-brand {
  color: #054169;
  background: rgb(255, 255, 255) !important;
}

.widget-btv-big {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.c-dark-theme .c-sidebar .c-sidebar-brand {
  background: rgba(0, 0, 21, 0.2) !important;
}

.table-col-center {
  td {
    vertical-align: middle !important;
  }
}

.whitespace-nowrap {
  white-space: nowrap;
}

.custom-fields-table {
  th,
  td {
    border: 1px solid transparent;
    padding: 4px;
    padding-right: 8px;
  }
  .no-border {
    border: none;
  }

  input {
    // border: 1px solid black;
    // color:black;
  }
}

.table-fit-content {
  th {
    width: 1%;
    white-space: nowrap;
  }
}

.standard-table {
  td {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.decoration-none {
  text-decoration: none !important;
}

.font-weight-bold-header {
  span {
    font-weight: bold;
  }
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.react-tagsinput-input {
  width: 15rem !important;
}

.min-width-10 {
  min-width: 10rem !important;
}

.progress {
  border-radius: 0;

  height: 1rem;
  background-color: #ced2d8;
}

.cursor-pointer {
  cursor: pointer;
}

.img-table {
  width: 5rem;
}

input:focus {
  outline: none;
}
.slider {
  cursor: pointer;
  -webkit-appearance: none;
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--val) - var(--min)) / var(--range));
  --sx: calc(0.5 * 1.5em + var(--ratio) * (100% - 1.5em));
  margin: 0;
  padding: 0;
  width: 100%;
  height: 1.5em;
  background: transparent;
  font: 1em/1 arial, sans-serif;
  border: none;
}
.slider,
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
}
.slider::-webkit-slider-runnable-track {
  box-sizing: border-box;
  border: none;
  width: 12.5em;
  height: 0.5em;
  background: #ced2d8;
}
.js .slider::-webkit-slider-runnable-track {
  background: linear-gradient(#7b1c1a, #7b1c1a) 0 / var(--sx) 100% no-repeat #ced2d8;
}
.slider::-moz-range-track {
  box-sizing: border-box;
  border: none;
  height: 0.5em;
  background: #ced2d8;
}
.slider::-ms-track {
  box-sizing: border-box;
  border: none;
  // width: 12.5em;
  height: 0.5em;
  background: #ced2d8;
}
.slider::-moz-range-progress {
  height: 0.5em;
  background: #321fdb;
}
.slider::-ms-fill-lower {
  height: 0.5em;
  background: #321fdb;
}
.slider::-webkit-slider-thumb {
  margin-top: -1em;
  box-sizing: border-box;
  border: none;
  width: 0.5em;
  height: 2.5em;
  border-radius: 0;
  background: #321fdb;
}
.slider::-moz-range-thumb {
  margin-top: -0.5em;
  box-sizing: border-box;
  border: none;
  width: 0.5em;
  height: 1.5em;
  border-radius: 0;
  background: #321fdb;
}
.slider::-ms-thumb {
  margin-top: -0.5em;
  box-sizing: border-box;
  border: none;
  width: 0.5em;
  height: 1.5em;
  border-radius: 0;
  background: #321fdb;
}
.slider::-ms-tooltip {
  display: none;
}

label {
  font-weight: 600;
}

.upcoming-date {
  display: inline-flex;
  text-align: center;
  color: #18603a;
  background-color: #c4ebd1;
  padding: 0 0.25em;
  border-radius: 0.5rem;
}

.ended-date {
  display: inline-flex;
  text-align: center;
  color: #772b35;
  background-color: #f8cfcf;
  padding: 0 0.25em;
  border-radius: 0.5rem;
}

.dashboard-cards .text-value {
  font-size: 1.5em;
}

.no-video-fallback {
  position: relative;
  max-width: 620px;
  height: 349px;
  img {
    max-width: 620px;
    max-height: 349px;
    object-fit: cover;
    filter: saturate(0);
  }
  div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.4);
    color: #f2f2f2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 2.2rem;
  }
}

.no-video-icon {
  position: absolute;
  top: 8px;
  z-index: 999;
  color: white;
  left: 3px;
  background-color: black;
  padding: 2px;
}

canvas {
  border: 1px solid black;
  overflow: hidden;
  position: fixed;
  left: -210%;
  top: -210%;
}

.text-white {
  fill: white;
  color: #fff !important;
}
.icon-white {
  fill: white;
}
.icon-black {
  fill: black;
}

.icon.icon-lg {
  height: 1.1rem !important;
}

table.card-table {
  width: 100%;
  tr:hover {
    background-color: #ededed;
  }
  th,
  td {
    padding: 0.4rem;
  }
}

.w-70 {
  width: 70% !important;
}

video {
  max-height: 800px !important;
}

.rich-text {
  h1,
  h2,
  h3,
  h4 {
    font-weight: bold;
    margin-bottom: 1.3rem !important;
  }
  h2 {
    font-size: 1.75rem !important;
  }
  p {
    font-size: 1rem;
    margin-bottom: 1rem !important;
  }
  blockquote {
    font-size: 1rem;
    font-style: italic;
    margin-bottom: 1rem !important;
    border-left: 4px solid #ccc;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  ul li {
    font-size: 1rem !important;
  }
  img {
    width: 100%;
    height: auto;
  }
}

.audio-player-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 330px;
}
